export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    //Menu button logic
    const menuBtn = document.querySelector('.menu-btn');
    const mobileNav = document.querySelector('.nav-primary');
    let menuOpen = false;
    menuBtn.addEventListener('click', () => {
      if (!menuOpen) {
        menuBtn.classList.add('open');
        mobileNav.classList.add('open');
        menuOpen = true;
      } else {
        menuBtn.classList.remove('open');
        mobileNav.classList.remove('open');
        menuOpen = false;
      }
    });

    //mobile submenu toggle carats
    const mobile_carat = '<svg class="toggle-carat" xmlns="http://www.w3.org/2000/svg" width="13.414" height="8.121" viewBox="0 0 13.414 8.121">\
      <path id="Path_3" data-name="Path 3" d="M21291.738,4838.676l-6,6-6-6" transform="translate(-21279.031 -4837.969)" fill="none" stroke="#000" stroke-width="2"/>\
    </svg>';

    $('.menu-item-has-children a').after(mobile_carat);
    $('.sub-menu .toggle-carat').remove();

    if($('.toggle-carat').length) {
      $('.toggle-carat').click(function() {
        $(this).toggleClass('active');
        $(this).next().toggleClass('active');
      });
    }

    //Enter Page cookie and logic
    $('#enter-site-button').click(function(){
      document.cookie = 'SFNTC_enter=true; expires=Fri, 31 Dec 2500 23:59:59 GMT';
      window.location.reload();
    });
  },
};
